/** @jsx jsx */
import React, { FunctionComponent } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { jsx } from "theme-ui";

import { BlogPost, Layout, SEO } from "../components";

export const BlogPostTemplate: FunctionComponent<{
  data: any;
}> = ({ data }) => {
  const post = data.mdx;
  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <BlogPost title={post.frontmatter.title} date={post.frontmatter.date} showPermalink={false}>
        <MDXRenderer sx={{ fontSize: "1rem", color: "rgb(45, 55, 72)" }}>{post.body}</MDXRenderer>
      </BlogPost>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`;
